var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    [
      _c(
        "v-container",
        [
          _c(
            "v-card",
            { staticClass: "elevation-12" },
            [
              _c(
                "v-toolbar",
                { attrs: { dark: "", color: "purple" } },
                [
                  _c("v-toolbar-title", [
                    _vm._v("SysClass - Registro de Presença")
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "mr-1",
                                      attrs: {
                                        icon: "",
                                        small: "",
                                        loading: _vm.loadingLogout
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.logout()
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_c("v-icon", [_vm._v("mdi-logout-variant")])],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Sair")])]
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "12" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _vm.teacher
                                    ? _c("v-card-title", [
                                        _vm._v(_vm._s(_vm.teacher.name))
                                      ])
                                    : _vm._e(),
                                  _vm.institution
                                    ? _c("v-card-subtitle", [
                                        _vm._v(_vm._s(_vm.institution.name))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold ml-8 mb-2"
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(_vm._s(_vm.formatedDate))
                                          ])
                                        ]
                                      ),
                                      _vm.sheet && _vm.sheet.items.length > 0
                                        ? _c(
                                            "v-timeline",
                                            {
                                              attrs: {
                                                "align-top": "",
                                                dense: ""
                                              }
                                            },
                                            _vm._l(_vm.times, function(
                                              time,
                                              index
                                            ) {
                                              return _c(
                                                "v-timeline-item",
                                                {
                                                  key: index,
                                                  attrs: {
                                                    color:
                                                      _vm.sheet.items[0]
                                                        .registers.length >
                                                      index
                                                        ? "success"
                                                        : "primary",
                                                    icon:
                                                      _vm.sheet.items[0]
                                                        .registers.length >
                                                      index
                                                        ? "mdi-checkbox-marked-circle"
                                                        : "mdi-minus-circle-outline",
                                                    small: ""
                                                  }
                                                },
                                                [
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "font-weight-normal"
                                                      },
                                                      [
                                                        _c("strong", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.sheet.items[0][
                                                                time.field
                                                              ]
                                                                .toString()
                                                                .substring(0, 5)
                                                            )
                                                          )
                                                        ]),
                                                        _vm._v(
                                                          ": " +
                                                            _vm._s(time.label) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _vm.sheet.items[0].registers
                                                      .length > index
                                                      ? _c("div", [
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "ml-1"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Registrado às: " +
                                                                  _vm._s(
                                                                    _vm.sheet
                                                                      .items[0]
                                                                      .registers[
                                                                      index
                                                                    ].time
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ])
                                                ]
                                              )
                                            }),
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "12" } },
                                [
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "deep-purple lighten-2",
                                            "x-large": "",
                                            dark: "",
                                            outlined: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.showMark = true
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(" Efetuar Checkin "),
                                          _c("v-icon", [
                                            _vm._v("mdi-clock-check-outline")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pa-1 ma-1 text-center" },
                [
                  _c("v-alert", {
                    domProps: { innerHTML: _vm._s(_vm.message) },
                    model: {
                      value: _vm.snackbar,
                      callback: function($$v) {
                        _vm.snackbar = $$v
                      },
                      expression: "snackbar"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("emc-alert-modal", {
            attrs: {
              text: "Confirma a marcação do checkin?",
              disabled: _vm.loadingMark,
              show: _vm.showMark
            },
            on: {
              "update:show": function($event) {
                _vm.showMark = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "actions",
                fn: function() {
                  return [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "deep-purple lighten-2",
                          loading: _vm.loadingMark,
                          disabled: _vm.loadingMark
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.mark()
                          }
                        }
                      },
                      [
                        _vm._v("Realizara Checkin"),
                        _c("v-icon", { attrs: { right: "", dark: "" } }, [
                          _vm._v("mdi-clock-check-outline")
                        ])
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          }),
          _c("emc-alert-snackbar", {
            attrs: {
              show: !!_vm.message.length,
              message: _vm.message,
              color: _vm.error ? "error" : "success"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }